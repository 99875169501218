import Service from "../Service";

const resource = "numerationdocument/";

export default {
    save(nud, requestID) {
        return Service.post(resource + "save", nud, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    current(TypeDocument) {
        let obj = { TypeDocument: TypeDocument }
        return Service.post("numerationdocument/current", obj);
    },
};