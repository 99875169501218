<template>
<div class="home">
    <v-row>
        <v-col lg="10" offset-md="1">
            <s-crud title="Numeración de Documentos" formPermanent sortable pdf excel edit add remove :config="this.config" @save="saveNud($event)">
                <template scope="props">
                    <v-container v-if="props.item != null" class="pb-0">
                        <v-row class="justify-center">
                            <v-col cols="12" md="4" class="s-col-form">
                                <s-select-definition :disabled="props.item.NudID > 0" :def="1162" v-model="props.item.TypeDocument" label="Tipo
                    Documento" />
                            </v-col>
                            <v-col cols="6" md="2" class="s-col-form">
                                <s-text v-model="props.item.NudSerie" label="Serie" />
                            </v-col>
                            <v-col cols="6" md="2" class="s-col-form">
                                <s-text v-model="props.item.NudCorrelative" label="Correlativo" />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </s-crud>
        </v-col>
    </v-row>
</div>
</template>

<script>
import _sNumeration from "@/services/General/NumerationDocumentService";
export default {
    data: () => ({
        config: {
            model: {
                NudID: "ID",
                NudSerie: "string",
                NudCorrelative: "string",
                TypeDocumentText: "string",
                TypeDocument: "boleam",
                SecStatus: "status",
            },

            service: _sNumeration,

            headers: [{
                    text: "ID",
                    align: "start",
                    sortable: false,
                    value: "NudID",
                },
                {
                    text: "Documento",
                    value: "TypeDocumentText",
                },
                {
                    text: "Serie",
                    value: "NudSerie",
                    sortable: false,
                },
                {
                    text: "Correlativo",
                    value: "NudCorrelative",
                    sortable: false,
                },
                {
                    text: "Estado",
                    value: "SecStatus",
                },
            ],
        },
    }),

    methods: {
        validatedNud(item) {
            let isValid = false;
            let message = "";

            isValid = item.TypeDocument > 0;

            if (!isValid) message = "Seleccione un tipo de documento";
            else {
                isValid = item.NudCorrelative >= 0;
                if (!isValid) message = "Registre correlativo válido";
            }

            if (!isValid) this.$fun.alert(message, "warning");

            return isValid;
        },
        saveNud(item) {
            if (this.validatedNud(item)) item.save();

            //
        },
    },
};
</script>
